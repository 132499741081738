import {useEffect, useRef, useState} from "react";

function AutoCompleteSearch(props) {
    // props ======================================================================
    const {onSelect = (v) => {}, onChange = (v) => {}, onSubmit = (v) => {}} = props;
    const {inputClass, listClass, optionClass, className} = props;
    const {placeholder, searching, id, options} = props;

    // states ======================================================================
    const [value, setValue] = useState("");
    const [cursor, setCursor] = useState(null);

    // references ==================================================================
    const parentRef = useRef();
    const listRef = useRef();
    const inputRef = useRef();

    const handleKeys = (evt) => {
        if (["Enter", "ArrowDown", "ArrowUp", "Escape"].includes(evt.key))
            evt.preventDefault();

        if (evt.key === "Enter") {
            if (cursor !== null) {
                setValue(options[cursor].name);
                onSelect(options[cursor].id);
            } else {
                onSubmit(value);
            }
            inputRef.current.blur();
        } else if (evt.key === "Escape") {
            evt.target.blur();
        } else if (["ArrowDown", "ArrowUp"].includes(evt.key) && options.length) {

            const nextIndex = evt.key === "ArrowDown" ?
                (cursor === null ? 0 : Math.min(cursor + 1, options.length - 1))
                :
                ((cursor === 0 || cursor === null) ? null : cursor - 1);

            setCursor(nextIndex);

            if (nextIndex !== null && listRef.current)
                listRef.current.children[nextIndex]?.scrollIntoViewIfNeeded(false);
        }
    }

    const handleClick = (evt, option) => {
        setValue(option.name);
        onSelect(option.id);
        listRef.current.style.display = 'none';
    }

    const handleFocus = () => {
        if (options && options.length) {
            if (listRef.current) listRef.current.style.display = '';
        } else {
            if (inputRef.current) inputRef.current.focus();
        }
    }

    const handleBlur = (evt) => {
        if (parentRef.current && listRef.current) {
            if (!parentRef.current.contains(evt.relatedTarget))
                listRef.current.style.display = 'none';
        }
    }

    useEffect(() => {
        onChange(value);
    }, [value, onChange])


    return <div id={id} ref={parentRef} className={`flex items-center relative ${className}`} onFocus={handleFocus}
                onBlur={handleBlur}>
        <span className={"material-symbols-rounded text-md"}>search</span>
        <input ref={inputRef}
            className={`outline-none ${inputClass}`}
            onChange={e => setValue(e.target.value)}
            value={value} type={"search"} autoComplete={"off"}
            placeholder={placeholder} onKeyDown={handleKeys}/>
        <ul tabIndex={1} ref={listRef} className={`absolute left-0 top-6 w-full ${(!options || !options.length  ) && 'hidden'} ${listClass}`}>
            {!searching && options && options.map((option, index) =>
                <li key={`${option.id}-${index}`} className={`${cursor === index && 'bg-gray-100'} hover:bg-gray-100 cursor-pointer select-none ${optionClass}`}
                    onClick={evt => handleClick(evt, option)}>
                    {option.name}
                </li>
            )}
            {searching && <li className={`flex items-center justify-center text-md`}>
                <span className={"material-symbols-rounded animate-pulse"}>more_horiz</span></li>}
        </ul>
    </div>

}

export default AutoCompleteSearch;