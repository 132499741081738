import React, { useEffect, useState } from "react";

function RaAnalysisSideBar({ invoiceData, setDescribeAnalysis }) {
  const [activeTab, setActiveTab] = useState("analysis");
  const [parsedSummary, setParsedSummary] = useState(null);

  function extractRecoveryAmount(summary) {
    try {
      const equationPattern = /=\s*\$?([\d,]+\.?\d*)/;
      const equationMatch = summary.match(equationPattern);
      if (equationMatch) {
        return parseFloat(equationMatch[1].replace(/,/g, ""));
      }
      return null;
    } catch (error) {
      console.error("Error extracting amount:", error);
      return null;
    }
  }

  const val = extractRecoveryAmount(invoiceData?.summary);
  console.log(val);

  useEffect(() => {
    if (invoiceData?.summary) {
      try {
        // Log the incoming summary to debug
        console.log("Raw summary:", invoiceData.summary);

        let parsedData;
        if (typeof invoiceData.summary === "string") {
          // Try to parse if it's a string
          parsedData = JSON.parse(invoiceData.summary);
        } else {
          // If it's already an object, use it directly
          parsedData = invoiceData.summary;
        }

        // Log the parsed data to debug
        console.log("Parsed summary:", parsedData);

        setParsedSummary({
          summary: parsedData,
        });
      } catch (e) {
        console.error("Error parsing summary:", e);
        // If parsing fails, try to use the summary as is
        setParsedSummary({
          summary: invoiceData.summary,
        });
      }
    }
  }, [invoiceData?.summary]);

  const tabData = {
    analysis: {
      label: "Analysis Details",
      content: [
        { label: "Invoice Number", value: invoiceData.invoice_number || "N/A" },
        { label: "PO Number", value: invoiceData.PO_Number || "N/A" },
        { label: "Priority", value: "Medium" },
        { label: "Remittance Advice Status", value: "Incorrect" },
        {
          label: "Recoverable Amount",
          value: val ? (
            <span className="font-bold text-blue-600">${val}</span>
          ) : (
            "N/A"
          ),
        },
        { label: "Uploaded on", value: invoiceData.created_at || "N/A" },
      ],
    },
    other: {
      label: "Other Information",
      content: [
        { label: "Invoice ID", value: invoiceData.invoice_id || "N/A" },
        { label: "Status", value: invoiceData.status || "N/A" },
      ],
    },
  };

  const SummarySection = ({ title, data }) => {
    if (!data || typeof data !== "object") return null;

    return (
      <div className="space-y-2 mb-4">
        <h4 className="font-medium text-gray-700">{title}</h4>
        <div className="ml-4 space-y-1">
          {Object.entries(data).map(([key, value]) => (
            <div key={key} className="text-gray-600">
              {typeof value === "object" ? (
                <div className="mb-2">
                  <span className="font-medium">{key}:</span>
                  <div className="ml-4 mt-1">
                    {Object.entries(value).map(([subKey, subValue]) => (
                      <div key={subKey} className="text-sm">
                        <span className="font-medium">{subKey}:</span>{" "}
                        {subValue}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <span className="font-medium">{key}:</span> {value}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const SummaryDisplay = ({ summary }) => {
    console.log("Summary Display Input:", summary); // Debug log

    if (!summary?.summary) {
      console.log("No summary data available"); // Debug log
      return null;
    }

    let summaryData = summary.summary;

    // Check if summaryData is a string (might be double encoded)
    if (typeof summaryData === "string") {
      try {
        summaryData = JSON.parse(summaryData);
      } catch (e) {
        console.error("Error parsing summary data:", e);
        return <div>Error displaying summary</div>;
      }
    }

    // Add debug log to see final data structure
    console.log("Final summary data:", summaryData);

    return (
      <div className="mt-3 border-t pt-3">
        <h3 className="text-md font-semibold text-gray-800 mb-3">
          Analysis Summary:
        </h3>
        <div className="rounded-lg bg-gray-50 p-4">
          {/* Volume Discount Section */}
          {summaryData["Volume Discount (VD)"] && (
            <SummarySection
              title="Volume Discount (VD)"
              data={summaryData["Volume Discount (VD)"]}
            />
          )}

          {/* Damages or Spoils Section */}
          {summaryData["Damages or Spoils (GP)"] && (
            <SummarySection
              title="Damages or Spoils (GP)"
              data={summaryData["Damages or Spoils (GP)"]}
            />
          )}

          {/* Manufacturer Chargeback Section */}
          {summaryData["Manufacturer Chargeback (MCB)"] && (
            <SummarySection
              title="Manufacturer Chargeback (MCB)"
              data={summaryData["Manufacturer Chargeback (MCB)"]}
            />
          )}

          {/* Total Amounts Section */}
          {(summaryData["Total Amount to Recover"] ||
            summaryData["Total Recovery Amount"]) && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              <h4 className="font-medium text-gray-700 mb-2">Total Amounts</h4>
              <div className="space-y-2">
                {summaryData["Total Amount to Recover"] && (
                  <div className="ml-4">
                    {Object.entries(summaryData["Total Amount to Recover"]).map(
                      ([key, value]) => (
                        <div key={key} className="flex justify-between text-sm">
                          <span className="text-gray-600">{key}:</span>
                          <span className="font-medium">{value}</span>
                        </div>
                      )
                    )}
                  </div>
                )}
                {summaryData["Total Recovery Amount"] && (
                  <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200">
                    <span className="font-medium text-gray-700">
                      Total Recovery Amount:
                    </span>
                    <span className="font-semibold text-blue-600">
                      {summaryData["Total Recovery Amount"]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const InfoCard = ({ title, children }) => (
    <div className=" rounded-lg p-2 w-full">
      <h3 className="text-sm font-semibold text-gray-900 mb-2 border-b border-gray-200  ">
        {title}
      </h3>
      {children}
    </div>
  );

  const InfoRow = ({ label, value }) => (
    <div className="flex justify-between items-center py-1">
      <span className="font-sm text-gray-800 font-bold">{label}</span>
      <span className="text-gray-600">{value}</span>
    </div>
  );

  return (
    <div className="flex items-stretch fixed h-full lg:max-w-screen-sm w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1">
      {/* Borders */}
      <div className="flex flex-col w-1">
        {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
          <div
            key={color}
            className="h-full w-1"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="flex flex-col w-full p-6 flex-1 overflow-y-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-1  top-0 bg-white py-1">
          <div className="overflow-hidden text-ellipsis flex-1 pr-4">
            <h2 className="text-lg font-semibold text-gray-900">
              Invoice Number: {invoiceData.invoice_number}
            </h2>
          </div>
          <button
            onClick={() => setDescribeAnalysis(null)}
            className="hover:bg-gray-100 p-2 rounded-full"
          >
            <span className="material-symbols-rounded">close</span>
          </button>
        </div>

        {/* Tabs */}
        <div className="flex border-b mb-6">
          {Object.entries(tabData).map(([key, data]) => (
            <button
              key={key}
              onClick={() => setActiveTab(key)}
              className={`px-8 py-3 font-medium text-sm transition-colors
                ${
                  activeTab === key
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
            >
              {data.label}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="space-y-1">
          {activeTab === "analysis" && (
            <>
              <div className=" rounded-lg p-3 pt-0 ">
                {tabData.analysis.content.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center py-1  last:border-0"
                  >
                    <span className="font-medium text-gray-700">
                      {item.label}
                    </span>
                    <span className="text-gray-600">{item.value}</span>
                  </div>
                ))}
              </div>

              {parsedSummary && <SummaryDisplay summary={parsedSummary} />}
            </>
          )}

          {activeTab === "other" && (
            <div className="space-y-6 ">
              <div className="grid grid-cols-2 gap-6">
                <InfoCard title="Invoice Information">
                  <InfoRow
                    label="Invoice Number"
                    value={invoiceData.invoice_number || "N/A"}
                  />
                  <InfoRow
                    label="PO Number"
                    value={invoiceData.PO_Number || "N/A"}
                  />
                  <InfoRow
                    label="Total Amount"
                    value={invoiceData.total_amount || "N/A"}
                  />
                  <InfoRow
                    label="Date Created"
                    value={invoiceData.created_at || "N/A"}
                  />
                </InfoCard>

                <InfoCard title="PO Information">
                  <InfoRow
                    label="PO Number"
                    value={invoiceData.PO_Number || "N/A"}
                  />
                  <InfoRow
                    label="Date Created"
                    value={invoiceData.created_at || "N/A"}
                  />
                </InfoCard>
              </div>

              <InfoCard title="Remittance Advice">
                <InfoRow
                  label="Document Id"
                  value={invoiceData.invoice_number || "N/A"}
                />
                <InfoRow
                  label="Date Created"
                  value={invoiceData.created_at || "N/A"}
                />
                <InfoRow
                  label="Total Amount paid"
                  value={invoiceData.total_amount || "N/A"}
                />
                <InfoRow label="Sender Name" value="N/A" />
              </InfoCard>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RaAnalysisSideBar;
