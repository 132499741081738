import React, { useState } from "react";
import { useNavigate, 
  Link, 
//  useLocation 
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormElement } from "../../components/Input";
import { login } from "../../api/auth";

export default function Login() {
  const navigate = useNavigate();
  // const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (email && password && ev.target.reportValidity()) {
      setLoading(true);

      login({ email, password })
        .then(([status, _]) => {
          switch (status) {
            case 200: {
              //   if (location.state && location.state.ref)
              //     window.location.href = location.state.ref;
              //   else navigate("/staging-app/dashboard");
              //   break;
              navigate("/staging-app/dashboard/admin/invoice-analysis");
              break;
            }
            case 404: {
              document.querySelector("input[name=email]").focus();
              setErrors({ email: "User doesn't exist" });
              break;
            }
            case 403: {
              document.querySelector("input[name=email]").focus();
              setErrors({ email: "Account is not verified" });
              break;
            }
            case 401: {
              document.querySelector("input[name=password]").focus();
              setErrors({ password: "Invalid password" });
              break;
            }
            default: {
              setErrors({ form: "Something went wrong" });
            }
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Helmet title={"Login | Nimbey"} />
      <form
        onSubmit={handleSubmit}
        className={"flex flex-col card w-full shadow"}
      >
        <span className={"text-lg font-bold"}>Login</span>
        <span className={"text-xs"}>
          New user?{" "}
          <Link
            to={"/staging-app/signup"}
            className={"text-accLinks font-semibold"}
          >
            Create an account
          </Link>
        </span>
        <div className={"flex flex-col w-full py-4 gap-3"}>
          <FormElement
            name={"email"}
            type={"email"}
            autoComplete={"email"}
            value={email}
            errors={errors}
            setValue={setEmail}
            required={true}
            autoFocus={true}
            maxLength={255}
          />
          <FormElement
            name={"password"}
            type={"password"}
            autoComplete={"current-password"}
            minLength={6}
            maxLength={40}
            required={true}
            errors={errors}
            value={password}
            setValue={setPassword}
            toggleView={true}
          />
          <div className={"w-full flex justify-end"}>
            <Link
              to={"/staging-app/reset-password"}
              className={"text-xs text-gray-600 hover:text-black font-medium"}
            >
              Forgot Password?
            </Link>
          </div>
          {errors.form && (
            <span className={"text-red-400 font-semibold text-xs"}>
              {errors.form}
            </span>
          )}

          <button
            type={"submit"}
            disabled={loading}
            className={
              "w-full px-4 py-3 bg-accentBack text-gray-200 rounded-xl flex items-center justify-center"
            }
          >
            <span
              className={`text-md animate-pulse material-symbols-rounded ${
                !loading && "invisible"
              } absolute`}
            >
              more_horiz
            </span>
            <span className={`text-sm ${loading && "invisible"}`}>Login</span>
          </button>
        </div>
      </form>
    </>
  );
}
