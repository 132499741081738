import React, { useEffect, useState } from "react";
import Sorter from "./Sorter";
import { previousOrders } from "../api/dashboard";
import Img from "./Img";
import FallbackImage from "../static/img/fallback.png";
import newItemImage from "../static/img/newItem.png";

import { Link } from "react-router-dom";


function OrderTemplate({ record, tab }) {

    const orders = (record.type === "newItem") ? [record] : record.orders;
    const hasMultiple = orders.length > 1;

    if (orders.length === 0) return;
    const orderDate = new Date(record.order_date);

    return <fieldset
        className={`flex flex-col gap-4 ${hasMultiple ? "border shadow-md py-3 mb-5" : "mb-3"} appearance-auto rounded-md w-full`}>
        {
            hasMultiple && <legend className={"mx-4 px-2 text-xss text-gray-600"}>
                <span>Cart #{record.id}</span>
            </legend>
        }
        {
            orders.map(o => <div className={`flex items-center w-full px-4`}>
                <div className={"flex items-center gap-3 w-1/2"}>
                    <Img src={record.type === "cart" ? o.product_image || FallbackImage : newItemImage}
                        className={"w-16 aspect-video object-contain"} />
                    <div className={"flex flex-col items-start"}>
                        {
                            record.type === "cart" ?
                                <Link to={`/staging-app/dashboard/orders/${o.id}`}
                                    className={"text-sm text-accent font-semibold"}>{o.product_name}</Link>
                                :
                                <span className={"text-sm text-accent font-semibold"}>
                                    {o.product_name}
                                </span>
                        }
                        <span className={"text-xs text-gray-600"}>{o.product_description}</span>
                    </div>
                </div>
                <div className={"flex items-center gap-3 flex-1"}>
                    <span className={"text-xs text-gray-600"}>
                        {orderDate.toLocaleDateString("default", { weekday: "long" })}<br />
                        {orderDate.toLocaleDateString("default", { day: "numeric", month: "short", year: "numeric" })}<br />
                    </span>
                </div>
                <div className={"flex items-center gap-3 flex-1"}>
                    {
                        record.type === "cart" ? <span className={"text-xs text-gray-600 font-medium"}>
                            ${Number(o.total_amount).toLocaleString("default", { currency: "usd" })}
                        </span> : <span className={"text-xs text-gray-600 font-medium"}>-</span>
                    }
                </div>
                <div className={"flex items-center gap-3 flex-1"}>
                    {tab === 0 && <span
                        className={"capitalize font-semibold text-xs rounded-xl px-3 py-1"}
                        style={{
                            ...(o.status === "pending" ? {
                                backgroundColor: "#FFAB0029", color: "#B76E00"
                            } : o.status === "completed" ? {
                                backgroundColor: "#118D57", color: "#22C55E29"
                            } : {
                                backgroundColor: "#d66666", color: "rgb(255,255,255)"
                            })
                        }}>{o.status}</span>}
                </div>
                <div className={"flex items-center gap-8 flex-1 justify-end"}>
                    {
                        record.type === "cart" && <Link className={"font-semibold text-xs text-cyan-500"}
                            to={`/staging-app/dashboard/orders/${o.id}`}>View details</Link>
                    }
                    <span className={"material-symbols-rounded text-sm"}>more_vert</span>
                </div>
            </div>)
        }
    </fieldset>
}


export default function PreviousOrders({ admin }) {
    const [data, setData] = useState([]);

    // table data
    useEffect(() => {
        previousOrders(admin)
            .then(([status, data]) => {
                if (status === 200) {
                    setData(data);
                }
            });
        // eslint-disable-next-line
    }, []);

    const tabs = ["All Orders", "Pending Approval", "Approved", "Delivered", "Declined"];
    const [tab, setTab] = useState(0);
    const [sort, setSort] = useState(null);
    const columns = {
        description: {
            id: "description", name: "Description", value: (p) => p.product_name
        }, date: {
            id: "date",
            name: tab === 3 ? "Delivered Date" : "Order Date",
            value: (p) => new Date(tab === 3 ? p.expected_delivery_date : p.order_date)
        }, amount: {
            id: "amount", name: "Total Amount", value: (p) => (p.total_amount)
        }, [tab === 0 ? "status" : undefined]: tab === 0 ? {
            id: "status", name: "Status", value: (props) => props.status
        } : {}
    }

    const tabFilteredData = [];
    for (let i of data) {
        if (i.type === "cart") {
            const cart = { ...i };
            cart.orders.filter(o => {
                if (tab === 1) {
                    return o.approval_status === "pending"
                } else if (tab === 2) {
                    return o.approval_status === "approved"
                } else if (tab === 3) {
                    return o.status === "delivered"
                } else if (tab === 4) {
                    return o.status === "declined"
                } else {// case 0
                    return true;
                }
            })
            tabFilteredData.push(cart);
        } else {    // type: "newItem"
            if ((() => {
                if (tab === 1) {
                    return i.approval_status === "pending"
                } else if (tab === 2) {
                    return i.approval_status === "approved"
                } else if (tab === 3) {
                    return i.status === "delivered"
                } else if (tab === 4) {
                    return i.status === "declined"
                } else {
                    return true
                }
            })()) {
                tabFilteredData.push(i);
            }
        }
    }

    if (sort) tabFilteredData.sort((a, b) => (sort.desc ? -1 : 1) * (columns[sort.id].value(b) > columns[sort.id].value(a) ? -1 : 1))

    return (<div className={"flex flex-col gap-3 w-full mb-10 xl:mb-0"}>
        <div className={"flex w-full items-center px-2 justify-between"}>
            <span className={"flex text-xl gap-1"}><b>Previous</b>Orders</span>
            <Sorter sort={sort} setSort={setSort} columns={columns} />
        </div>
        <div className={"flex w-full flex-col gap-4 xl:gap-2 card p-4"}>
            <div className={"flex items-stretch w-full h-full overflow-x-auto bg-gray-50 rounded-md shadow"}>
                {tabs.map((t, i) => (
                    <button
                        onClick={() => setTab(i)}
                        key={t}
                        className={`hover:bg-gray-100 px-4 py-3 text-sm whitespace-nowrap ${tab === i
                                ? "font-bold text-accLinks border-accLinks bg-gray-50 border-b-2"
                                : "font-semibold"
                            }`}
                    >
                        {t}
                    </button>
                ))}
            </div>
            {/*  columns  */}
            <div className={"flex items-center bg-gray-100 p-4 w-full rounded-md"}>
                {Object.values(columns).map((c) => <button key={c.id}
                    onClick={() => setSort({ id: c.id, desc: !sort?.desc })}
                    className={`flex ${(c.id === "description" ? "w-1/2" : "flex-1")} items-center gap-2`}>
                    <span
                        className={`capitalize text-[13px] font-semibold text-gray-600 ${c.id === "status" && "px-3"}`}>{c.name}</span>
                    {sort && sort.id === c.id && <span
                        title={sort.desc ? "Descending" : "Ascending"}
                        className={"material-symbols-rounded text-sm text-gray-600"}>
                        {sort.desc ? "arrow_downward" : "arrow_upward"}
                    </span>}
                </button>)}
                <div className={"flex flex-1"}></div>
            </div>
            {/*  rows  */}
            <div className={"flex flex-col w-full gap-3"}>
                {tabFilteredData.map(data => <OrderTemplate key={data.id} record={data} tab={tab} />)}
            </div>
        </div>
    </div>)
}
