import React, { useEffect, useState } from "react";

function AnalysisSideBar({ record_id, summaryData, setDescribeAnalysis }) {
  const [parsedSummary, setParsedSummary] = useState(null);

  useEffect(() => {
    if (summaryData) {
      try {
        const parsed = JSON.parse(summaryData);
        setParsedSummary(parsed);
      } catch (e) {
        console.error("Error parsing summary:", e);
        setParsedSummary(null);
      }
    }
  }, [summaryData]);

  const renderSummarySection = () => {
    if (!parsedSummary) return <p>No analysis summary available</p>;

    return (
      <div className="flex flex-col gap-4">
        {/* Top Level Summary Information */}
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <span className="font-bold">Invoice Status:</span>
            {/* <span>{parsedSummary["Invoice Status"]}</span> */}
            <span>pending</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">Incorrect Amount:</span>
            <span>{parsedSummary["Incorrect Amount"]}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="font-bold">Total Incorrect Charges:</span>
            <span>{parsedSummary["Total Incorrect Charges"]}</span>
          </div>
        </div>

        {/* Detailed Summary Section */}
        {parsedSummary.Summary && (
          <div className="mt-6">
            <h3 className="font-bold mb-4 text-gray-700">Detailed Analysis</h3>
            {Object.entries(parsedSummary.Summary).map(([key, value]) => (
              <div key={key} className="mb-4 pb-3 border-b border-gray-100">
                <h4 className="font-semibold mb-2 text-gray-800">{key}</h4>
                <p className="text-sm text-gray-600 leading-relaxed">{value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex items-stretch fixed h-full lg:max-w-screen-sm w-full shadow-xl bg-white right-0 bottom-0 z-50 flex-1 overflow-x-auto lg:overflow-hidden lg:overflow-y-auto light-scroll">
      {/* Borders */}
      <div className="flex flex-col w-[5px] h-full">
        {["#005B8C", "#00A7C9", "#A58EFD", "#005162"].map((color) => (
          <div
            key={color}
            className="h-full w-[5px]"
            style={{ backgroundColor: color }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="flex flex-col w-full pt-6 px-6 flex-1 h-full">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <div className="overflow-hidden text-ellipsis w-3/4">
            <span className="font-bold text-md text-nowrap">
              Invoice Number: {record_id}
            </span>
          </div>
          <button onClick={() => setDescribeAnalysis(null)}>
            <span className="material-symbols-rounded text-md">close</span>
          </button>
        </div>

        {/* Summary Content */}
        <div className="flex flex-col w-full">
          <span className="text-gray-400 font-bold my-4 text-sm border-b pb-2">
            Analysis Summary
          </span>
          {renderSummarySection()}
        </div>
      </div>
    </div>
  );
}

export default AnalysisSideBar;
