import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./static/css/main.css";

// Router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AuthenticationLayout from "./layouts/AuthenticationLayout";
import Login from "./views/auth/Login";
import SignUp from "./views/auth/SignUp";
import ResetPassword from "./views/auth/ResetPassword";

import ResetSuccess from "./views/auth/ResetSuccess";
import Activate from "./views/auth/Activate";
import DashboardLayout from "./layouts/DashboardLayout";
import Home from "./views/dashboard/Home";
import AdminContracts from "./views/dashboard/admin/AdminContracts";
import ProductDetails from "./views/dashboard/ProductDetails";
import Products from "./views/dashboard/Products";
import OrderDetails from "./views/dashboard/OrderDetails";
import OrderRequest from "./views/dashboard/OrderRequest";
import NewItemOrder from "./views/dashboard/NewItemOrder";
import AdminDashboard from "./views/dashboard/admin/AdminDashboard";
import Page404 from "./pages/404";
import SuccessPage from "./pages/SuccessPage";
import SideQuests from "./views/side-quests/SideQuests";
import Nimbey from "./views/Nimbey";
import PreviousOrders from "./components/PreviousOrders";
import SideQuestLayout from "./layouts/SideQuestLayout";
import ActivateSuccess from "./views/auth/ActivateSuccess";
import Cart from "./views/dashboard/Cart";
import { Provider } from "react-redux";
import store from "./redux/store";
import AdminInvoiceAnalysis from "./views/dashboard/admin/AdminInvoiceAnalysis";
import AdminRaAnalysis from "./views/dashboard/admin/admin_RA_Analysis";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path={"/"} element={<Nimbey />} />
            <Route path={"/staging-app"} errorElement={<Page404 />}>
              <Route path={""} element={<AuthenticationLayout />}>
                <Route path={"login"} element={<Login />} />
                <Route path={"signup"} element={<SignUp />} />
                <Route path={"reset-password"} element={<ResetPassword />} />
                <Route path={"reset-success"} element={<ResetSuccess />} />
                <Route
                  path={"activate-success"}
                  element={<ActivateSuccess />}
                />
                <Route path={"activate"} element={<Activate />} />
              </Route>
              <Route
                path={"dashboard"}
                element={<App />}
                errorElement={<Page404 />}
              >
                <Route path={""} element={<DashboardLayout />}>
                  <Route path={""} element={<Home />} />
                  <Route path={"search"} element={<Products />} />
                  <Route
                    path={"admin/contract-upload"}
                    element={<AdminContracts />}
                  />
                  <Route path={"products"} element={<Products />} />
                  <Route
                    path={"products/:productID"}
                    element={<ProductDetails />}
                  />
                  <Route path={"orders/new-item"} element={<NewItemOrder />} />
                  <Route path={"orders/cart"} element={<Cart />} />
                  <Route
                    path={"orders/approve/:cartID"}
                    element={<OrderRequest />}
                  />
                  <Route path={"orders/:orderID"} element={<OrderDetails />} />
                  <Route path={"admin"} element={<AdminDashboard />} />

                  <Route
                    path={"admin/orders"}
                    element={<PreviousOrders admin={true} />}
                  />
                  <Route
                    path={"admin/invoice-analysis"}
                    element={<AdminInvoiceAnalysis />}
                  />
                  <Route
                    path={"admin/ra-analysis"}
                    element={<AdminRaAnalysis />}
                  />
                  <Route path={"success"} element={<SuccessPage />} />
                </Route>
              </Route>
            </Route>
            <Route
              path={"/side-quest"}
              element={<SideQuestLayout />}
              errorElement={<Page404 />}
            >
              <Route path={""} element={<></>} />
              <Route path={"p/:uri"} element={<SideQuests />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </StrictMode>
);
