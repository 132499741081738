// import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import InvoiceUploadModal from "../../../components/InvoiceUploadModal";
import { SiQuickbooks } from "react-icons/si";
import {
  adminInvoices,
  deleteAnalysis,
  quickbooks,
} from "../../../api/dashboard";
import Notify from "../../../components/Notification";
import AnalysisSideBar from "../../../components/AnalysisSideBar";
import { DocumentDownload } from "../../../components/DropDown";

function AdminInvoiceAnalysis() {
  // const { chat } = useOutletContext();
  const [invoices, setInvoices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [describeAnalysis, setDescribeAnalysis] = useState(null);
  const initialLoadDone = useRef(false);

  const getInvoices = (isInitialLoad = false) => {
    setLoader(true);
    adminInvoices()
      .then(([status, response]) => {
        if (status === 200 && Array.isArray(response)) {
          const uniqueInvoices = new Map();
          response.forEach((invoice) => {
            const currentInvoice = uniqueInvoices.get(invoice.invoice_number);
            const currentDate = new Date(
              invoice.created_at || invoice.invoice_date || new Date()
            );
            if (
              !currentInvoice ||
              new Date(currentInvoice.created_at) < currentDate
            ) {
              // Ensure docs array is properly formatted
              const formattedDocs =
                invoice.documents?.map((doc) => ({
                  id: doc.id,
                  s3_key: doc.s3_key,
                  name: doc.s3_key.split("/").pop(), // Get filename from s3_key
                })) || [];
              uniqueInvoices.set(invoice.invoice_number, {
                ...invoice,
                created_at:
                  invoice.created_at ||
                  invoice.invoice_date ||
                  new Date().toISOString(),
                docs: formattedDocs, // Add formatted docs
              });
            }
          });
          const formattedInvoices = Array.from(uniqueInvoices.values()).map(
            (invoice) => ({
              invoice_id: invoice.id || "N/A",
              invoice_number: invoice.invoice_number || "N/A",
              total_amount: invoice.total_amount || "$0.00",
              payment_due_date: invoice.payment_due_date || "N/A",
              created_at: invoice.created_at,
              status: invoice.status || "Pending",
              billed_to_name: invoice.billed_to_name || "N/A",
              summary: invoice.summary || null,
              recoverable_amount: invoice.recoverable_amount || "N/A",
              doc_id: invoice.summary_id || "",
            })
          );
          console.log(formattedInvoices);
          setInvoices(formattedInvoices);

          if (!initialLoadDone.current && isInitialLoad) {
            initialLoadDone.current = true;
          }
        } else {
          console.error("Invalid response format:", response);

          setInvoices([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);

        setInvoices([]);
      })
      .finally(() => setLoader(false));
  };

  const handleDelete = (invoice_id) => {
    if (!invoice_id) {
      Notify("Invalid invoice ID", "error");
      return;
    }
    deleteAnalysis(invoice_id)
      .then(([status, data]) => {
        if (status === 200) {
          getInvoices(false);
          Notify("Invoice deleted successfully", "success");
        } else {
          Notify("Failed to delete invoice", "error");
        }
      })
      .catch((error) => {
        console.error("Error deleting invoice:", error);
        Notify("Error deleting invoice", "error");
      });
  };

  const handleMarkResolved = async (event, invoice) => {
    event.stopPropagation();
    try {
      Notify("Uploading to Quickbooks", "info");
      const response = await quickbooks(invoice.invoice_id);
      console.log("Processed response:", response); // Debug log

      if (response.status === 200) {
        Notify(
          `Invoice No.${invoice.invoice_number} Uploaded to Quickbooks`,
          "success"
        );
      } else if (response.status === 404) {
        Notify("Invoice already exists in QuickBooks", "success");
      } else {
        Notify(response.message || "Failed to upload to QuickBooks", "error");
      }
    } catch (error) {
      console.error("QuickBooks Error:", error);
      Notify("Failed to upload to QuickBooks", "error");
    }
  };

  useEffect(() => {
    getInvoices(true);
    return () => {
      initialLoadDone.current = false;
    };
  }, []);

  if (loader) {
    return (
      <div className="flex w-full items-center justify-center flex-1">
        <span className="w-full text-center block">
          <span className="material-symbols-rounded animate-spin">
            progress_activity
          </span>
        </span>
      </div>
    );
  }

  // Define common column widths
  // const columnStyles = {
  //   invoiceNumber: "w-[25%]",
  //   billedTo: "w-[25%]",
  //   amount: "w-[16%]",
  //   dueDate: "w-[16%]",
  //   status: "w-[18%]",
  // };

  const renderTableHeader = () => (
    <div className="rounded-md mt-4 w-full hidden lg:flex items-center justify-between bg-gray-100 border mb-4">
      <div className="flex items-center flex-[4] p-4">
        <span className="w-1/4 font-semibold text-sm text-gray-600">
          Invoice Number
        </span>
        <span className="w-1/4 font-semibold text-sm text-gray-600">
          Billed To
        </span>
        <span className="w-1/6 font-semibold text-sm text-gray-600">
          Total Amount
        </span>
        <span className="w-1/6 font-semibold text-sm text-gray-600">
          Due Date
        </span>
        <span className="w-1/6 font-semibold text-sm text-gray-600">
          Status
        </span>
      </div>
      <div className="flex-[0.5] flex items-center font-semibold p-4 justify-end text-gray-600">
        Actions
      </div>
    </div>
  );

  const renderMobileCard = (invoice) => (
    <div className="flex flex-col p-4 border rounded-md mb-4 bg-white shadow-sm">
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center gap-2">
          <span className="material-symbols-rounded text-gray-200">
            receipt_long
          </span>
          <span
            onClick={() => setDescribeAnalysis(invoice.invoice_number)}
            className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
          >
            Invoice: {invoice.invoice_number}
          </span>
        </div>
        <div className="flex gap-2">
          <button
            title="Mark as resolved"
            onClick={(e) => handleMarkResolved(e, invoice)}
            className="material-symbols-rounded text-green-500 hover:text-green-600 text-xl"
          >
            check
          </button>
          <button
            onClick={() => handleDelete(invoice.invoice_id)}
            className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
            title="Delete"
          >
            delete
          </button>
          <DocumentDownload invoice={invoice} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-y-2 text-sm">
        <div className="text-gray-600">Billed To:</div>
        <div className="font-medium">{invoice.billed_to_name}</div>

        <div className="text-gray-600">Total Amount:</div>
        <div className="font-semibold">{invoice.total_amount}</div>

        <div className="text-gray-600">Due Date:</div>
        <div className="font-medium">{invoice.payment_due_date}</div>

        <div className="text-gray-600">Status:</div>
        <div className="font-medium">{invoice.status}</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-start w-full gap-2 rounded-md relative flex-1 p-4">
      <div className="py-1 w-full">
        <span className="flex text-lg gap-1 w-full">
          <b>Analysis</b>
        </span>
      </div>

      <div className="card flex flex-col w-full h-full">
        <div className="w-full">
          <InvoiceUploadModal
            getInvoices={() => getInvoices(false)}
            onUploadStart={() => Notify("Uploading...", "info")}
          />
        </div>

        <div className="w-full flex flex-col">
          {/* Desktop Table Header */}
          {renderTableHeader()}

          {/* Responsive Content */}
          <div className="w-full">
            {invoices.length > 0 ? (
              <>
                {/* Desktop View */}
                <div className="hidden lg:flex flex-col w-full gap-2">
                  {invoices
                    .sort((a, b) => {
                      try {
                        return new Date(b.created_at) - new Date(a.created_at);
                      } catch (e) {
                        return 0;
                      }
                    })
                    .map((invoice) => (
                      <div
                        key={`desktop-${invoice.invoice_number}`}
                        className="flex items-center w-full border rounded-md hover:bg-gray-50"
                      >
                        <div className="flex items-center flex-[4] p-4">
                          <div className="w-1/4 flex items-center gap-2">
                            <span className="material-symbols-rounded text-gray-600">
                              receipt_long
                            </span>
                            <span
                              onClick={() =>
                                setDescribeAnalysis(invoice.invoice_number)
                              }
                              className="font-medium truncate cursor-pointer hover:text-blue-600 hover:underline"
                            >
                              Invoice: {invoice.invoice_number}
                            </span>
                          </div>
                          <span className="w-1/4 truncate text-left pr-2 font-medium">
                            {invoice.billed_to_name}
                          </span>
                          <span className="w-1/6 font-semibold text-left">
                            {invoice.total_amount}
                          </span>
                          <span className="w-1/6 text-left text-gray-600 font-medium">
                            {invoice.payment_due_date}
                          </span>
                          <span className="w-1/6 text-gray-600 text-left font-medium">
                            {invoice.status}
                          </span>
                        </div>
                        <div className="flex-[0.5] flex items-center gap-4 justify-end p-4">
                          <button
                            title="Upload to QuickBooks"
                            onClick={(e) => handleMarkResolved(e, invoice)}
                            className="transition-all duration-200 ease-in-out p-2 rounded-full hover:bg-green-100 group flex items-center justify-center active:scale-75"
                          >
                            <SiQuickbooks className="text-xl text-[#2CA01C] group-hover:scale-105 transition-transform duration-200" />
                          </button>
                          <button
                            onClick={() => handleDelete(invoice.invoice_id)}
                            className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
                            title="Delete"
                          >
                            delete
                          </button>
                          <DocumentDownload invoice={invoice} />
                        </div>
                      </div>
                    ))}
                </div>

                {/* Mobile View */}
                <div className="lg:hidden">
                  {invoices
                    .sort((a, b) => {
                      try {
                        return new Date(b.created_at) - new Date(a.created_at);
                      } catch (e) {
                        return 0;
                      }
                    })
                    .map((invoice) => renderMobileCard(invoice))}
                </div>
              </>
            ) : (
              <div className="w-full flex items-center justify-center p-3 text-sm text-gray-600">
                <span>No invoices available</span>
              </div>
            )}
          </div>
        </div>

        {describeAnalysis && (
          <AnalysisSideBar
            record_id={describeAnalysis}
            summaryData={
              invoices.find((inv) => inv.invoice_number === describeAnalysis)
                ?.summary
            }
            setDescribeAnalysis={setDescribeAnalysis}
          />
        )}
      </div>
    </div>
  );
}

export default AdminInvoiceAnalysis;
