import React, {useEffect} from "react";
import NimbeyLogoDark from "./../static/img/logos/NimbeyLogo-dark.svg";
import Img from "../components/Img";
import {Outlet, Link, useNavigate, useLocation} from "react-router-dom";


export default function AuthenticationLayout() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (["/staging-app/", "/staging-app"].includes(location.pathname)) navigate("/staging-app/dashboard");
        // eslint-disable-next-line
    }, []);


    return <div
        className={"flex flex-col gap-10 items-center justify-center w-full h-full overflow-y-auto py-24 bg-gray-100"}>
        <div className={"max-w-96 flex flex-col gap-4 w-full"}>
            <Link to={"/"} className={"px-2 w-fit"}><Img src={NimbeyLogoDark} alt={"Nimbey"} className={"w-28"}/></Link>
            <div className={"flex flex-col w-full"}>
                <Outlet />
            </div>
        </div>
    </div>
}
