import {useEffect, useState} from "react";
import {getNewsArticles} from "../../../api/sideQuest";

function NewsCard({data}) {
    const [articles, setArticles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState(null);

    useEffect(() => {
        if (data.profile.name === query) return;
        setLoading(true);
        setQuery(data.profile.name);
        getNewsArticles(data.profile.name)
            .then(([status, data]) => {
            if (status === 200) {
                const articles = {};
                for (let article of data) {
                    articles[article.url] = article;
                }
                setArticles(articles);
            }
        })
            .finally(() => setLoading(false));
    }, [data, query])

    if (query)
        return <div className={"flex flex-col md:items-stretch xl:min-w-60 2xl:min-w-80 flex-1 max-h-96 card p-0"}>
            <div className={"flex items-center gap-2 p-4 pb-0"}>
                <span className={"material-symbols-rounded text-md"}>drag_indicator</span>
                <span className={"text-sm font-semibold"}>News & Articles</span>
            </div>
            <div className={"p-4 w-full h-full overflow-hidden"}>
                {
                    articles && Object.keys(articles).length ?
                        <ul className={"flex overflow-y-auto light-scroll gray-scroll h-full flex-col gap-2"}>
                                {
                                    Object.values(articles).length ? Object.values(articles).map((a, index) =>
                                            <li key={`${index}:${a.url}`}
                                                className={"flex overflow-hidden items-stretch min-h-[68px] w-full text-xs"}>
                                                <div
                                                    className={"flex items-center justify-center rounded-md overflow-hidden w-full max-w-32 bg-gray-100 flex-1 relative"}>
                                                    <img id={'img::' + a.url} src={a.urlToImage}
                                                         alt={""} onError={() => {
                                                        document.querySelector(`img[id='img::${a.url}']`).remove();
                                                    }}
                                                         className={"z-10"}/>
                                                    <span
                                                        className="absolute text-xl material-symbols-rounded">photo</span>
                                                </div>
                                                <div className={"flex flex-col px-2 flex-[2]"}>
                                                    <a href={a.url} rel={"noreferrer"} target={"_blank"}
                                                       className={"font-medium text-xss block"}>{a.title}</a>
                                                    <span
                                                        className={"text-xss text-gray-400 block overflow-hidden text-ellipsis"}>{a.description?.toString().slice(0, 100)}...</span>
                                                </div>
                                            </li>
                                        )
                                        :
                                        !loading ? <div className={"flex flex-col flex-1"}>
                                            <span className={"text-xs"}>No recent news updates available</span>
                                        </div> : <></>
                                }
                            </ul>
                        :
                        <div className={"flex items-center justify-center w-full p-4"}>
                            {
                                loading ? <span
                                        className={"material-symbols-rounded animate-spin text-md"}>progress_activity</span>
                                    :
                                    <span className={"text-center text-xs"}>No recent articles available</span>
                            }
                        </div>
                }
            </div>
        </div>
}

export default NewsCard;