// import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import RAUploadModal from "../../../components/RAUploadModal";
import { adminRAInvoices, deleteRaAnalysis } from "../../../api/dashboard";
import Notify from "../../../components/Notification";
import RaAnalysisSideBar from "../../../components/RaAnalysisSideBar";
import {
  // DropDown,
  // DocumentDownload,
  RAAnalysisDownload,
} from "../../../components/DropDown";

function AdminRaAnalysis() {
  // const { chat } = useOutletContext();
  const [invoices, setInvoices] = useState([]);
  const [loader, setLoader] = useState(false);
  const [describeAnalysis, setDescribeAnalysis] = useState(null);
  const initialLoadDone = useRef(false);

  const getInvoices = () => {
    setLoader(true);
    adminRAInvoices()
      .then(([status, response]) => {
        if (status === 200 && Array.isArray(response)) {
          // Create a map to store unique invoices by invoice number
          const uniqueInvoices = new Map();

          // Process each invoice
          response.forEach((invoice) => {
            const currentInvoice = uniqueInvoices.get(invoice.invoice_number);
            const currentDate = new Date(
              invoice.created_at || invoice.invoice_date || new Date()
            );

            // Only update if this is a new invoice or if this version is newer
            if (
              !currentInvoice ||
              new Date(currentInvoice.created_at) < currentDate
            ) {
              uniqueInvoices.set(invoice.invoice_number, {
                ...invoice,
                created_at:
                  invoice.created_at ||
                  invoice.invoice_date ||
                  new Date().toISOString(),
              });
            }
          });

          // Convert map values to array and format
          const formattedInvoices = Array.from(uniqueInvoices.values()).map(
            (invoice) => ({
              invoice_id: invoice.id || "N/A",
              invoice_number: invoice.invoice_number || "N/A",
              total_amount: invoice.total_amount || "$0.00",
              payment_due_date: invoice.payment_due_date || "N/A",
              created_at: invoice.created_at,
              status: invoice.status || "Pending",
              billed_to_name: invoice.billed_to_name || "N/A",
              summary: invoice.summary || null,
              recoverable_amount: invoice.recoverable_amount || "N/A",
              PO_Number: invoice.Po_number || "",
              ra_doc_id: invoice.summary_id || "",
            })
          );

          setInvoices(formattedInvoices);
          console.log(formattedInvoices);
          if (!initialLoadDone.current) {
            initialLoadDone.current = true;
          }
        } else if (status === 200 && !Array.isArray(response)) {
          // Handle empty response without error
          setInvoices([]);
          if (!initialLoadDone.current) {
            initialLoadDone.current = true;
          }
        } else {
          console.error("Invalid response format:", response);

          setInvoices([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
        Notify("Error fetching invoices", "error");
        setInvoices([]);
      })
      .finally(() => setLoader(false));
  };

  const handleDelete = (event, invoice_id) => {
    event.stopPropagation();
    if (!invoice_id) {
      Notify("Invalid invoice ID", "error");
      return;
    }
    deleteRaAnalysis(invoice_id)
      .then(([status, data]) => {
        if (status === 200) {
          getInvoices();
          Notify("Invoice deleted successfully", "success");
        } else {
          Notify("Failed to delete invoice", "error");
        }
      })
      .catch((error) => {
        console.error("Error deleting invoice:", error);
        Notify("Error deleting invoice", "error");
      });
  };

  const handleMarkResolved = (event, invoice) => {
    event.stopPropagation();
    // Implement mark as resolved functionality here
    Notify("Mark as resolved functionality coming soon", "info");
  };

  useEffect(() => {
    getInvoices();
    return () => {
      initialLoadDone.current = false;
    };
  }, []);

  if (loader) {
    return (
      <div className="flex w-full items-center justify-center flex-1">
        <span className="w-full text-center block">
          <span className="material-symbols-rounded animate-spin">
            progress_activity
          </span>
        </span>
      </div>
    );
  }

  const renderTableHeader = () => (
    <div className="rounded-md mt-4 w-full hidden lg:flex bg-gray-100 border mb-4">
      <div className="flex items-center w-full px-4 py-3">
        <div className="w-1/4 font-semibold text-sm text-gray-600">
          Invoice Number
        </div>
        <div className="w-1/5 font-semibold text-sm text-gray-600">
          Uploaded on
        </div>
        <div className="w-1/5 font-semibold text-sm text-gray-600">
          Recoverable Amount
        </div>
        <div className="w-1/6 font-semibold text-sm text-gray-600">
          Priority
        </div>
        <div className="w-1/6 font-semibold text-sm text-gray-600">
          Charge Status
        </div>
        <div className="w-1/12 font-semibold text-sm text-gray-600 text-right">
          Actions
        </div>
      </div>
    </div>
  );

  const renderMobileCard = (invoice) => (
    <div className="flex flex-col p-4 border rounded-md mb-4 bg-white shadow-sm">
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center gap-2">
          <span className="material-symbols-rounded text-gray-600">
            receipt_long
          </span>
          <span
            onClick={() => setDescribeAnalysis(invoice.invoice_number)}
            className="font-medium cursor-pointer hover:text-blue-600 hover:underline"
          >
            Invoice: {invoice.invoice_number}
          </span>
        </div>
        <div className="flex gap-2">
          <button
            title="Mark as resolved"
            onClick={(e) => handleMarkResolved(e, invoice)}
            className="material-symbols-rounded text-green-500 hover:text-green-600 text-xl"
          >
            check
          </button>
          <button
            title="Delete"
            onClick={(e) => handleDelete(e, invoice.invoice_id)}
            className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
          >
            delete
          </button>
          <RAAnalysisDownload invoice={invoice} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-y-2 text-sm">
        <div className="text-gray-600">Uploaded on:</div>
        <div>{invoice.created_at}</div>

        <div className="text-gray-600">Recoverable Amount:</div>
        <div className="font-semibold">{invoice.total_amount}</div>

        <div className="text-gray-600">Priority:</div>
        <div>{"Medium"}</div>

        <div className="text-gray-600">Charge Status:</div>
        <div>{"Incorrect"}</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-start w-full gap-2 rounded-md relative flex-1 p-4">
      <div className="py-1 w-full">
        <span className="flex text-lg gap-1 w-full">
          <b>RA Analysis</b>
        </span>
      </div>

      <div className="card flex flex-col w-full h-full">
        <div className="w-full">
          <RAUploadModal
            getInvoices={getInvoices}
            onUploadStart={() => Notify("Uploading...", "info")}
          />
        </div>

        <div className="w-full flex flex-col">
          {/* Desktop Table Header */}
          {renderTableHeader()}

          {/* Responsive Content */}
          <div className="w-full">
            {invoices.length > 0 ? (
              <>
                {/* Desktop View */}
                <div className="hidden lg:flex flex-col w-full gap-2">
                  {invoices.map((invoice) => (
                    <div
                      key={`desktop-${invoice.invoice_number}`}
                      className="flex items-center w-full border rounded-md hover:bg-gray-50"
                    >
                      <div className="flex items-center w-full px-4 py-3">
                        <div className="w-1/4 flex items-center gap-2">
                          <span className="material-symbols-rounded text-gray-600">
                            receipt_long
                          </span>
                          <span
                            onClick={() =>
                              setDescribeAnalysis(invoice.invoice_number)
                            }
                            className="font-medium truncate cursor-pointer hover:text-blue-600 hover:underline"
                          >
                            Invoice: {invoice.invoice_number}
                          </span>
                        </div>
                        <div className="w-1/5 text-gray-600 truncate">
                          {invoice.created_at}
                        </div>
                        <div className="w-1/5 font-semibold truncate">
                          {invoice.total_amount}
                        </div>
                        <div className="w-1/6 text-gray-600 truncate">
                          {"Medium"}
                        </div>
                        <div className="w-1/6 truncate">{"Incorrect"}</div>
                        <div className="w-1/12 flex items-center justify-end gap-3">
                          <button
                            title="Mark as resolved"
                            onClick={(e) => handleMarkResolved(e, invoice)}
                            className="material-symbols-rounded text-green-500 hover:text-green-600 text-xl"
                          >
                            check
                          </button>
                          <button
                            title="Delete"
                            onClick={(e) => handleDelete(e, invoice.invoice_id)}
                            className="material-symbols-rounded text-gray-600 hover:text-gray-700 text-xl"
                          >
                            delete
                          </button>
                          <RAAnalysisDownload invoice={invoice} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Mobile View */}
                <div className="lg:hidden">
                  {invoices.map((invoice) => renderMobileCard(invoice))}
                </div>
              </>
            ) : (
              <div className="w-full flex items-center justify-center p-3 text-sm text-gray-600">
                <span>No invoices available</span>
              </div>
            )}
          </div>
        </div>

        {describeAnalysis && (
          <RaAnalysisSideBar
            invoiceData={
              invoices.find((inv) => inv.invoice_number === describeAnalysis) ||
              {}
            }
            setDescribeAnalysis={setDescribeAnalysis}
          />
        )}
      </div>
    </div>
  );
}

export default AdminRaAnalysis;
