import { createSlice, 
   // createAsyncThunk 
} from "@reduxjs/toolkit";
// import { getCart, updateCart } from "../api/dashboard";


const cartSlice = createSlice({
    name: "cart",
    initialState: {
        id: null, products: [],
    },
    reducers: {
        setCart: (state, action) => {
            return { ...action.payload };
        },
        addProduct: (state, action) => {
            return { ...state, products: [...state.products, { ...action.payload }] };
        },
        updateProduct: (state, action) => {
            const index = state.products.findIndex(product => product.product_id === action.payload.product_id);
            if (index !== -1) {
                if (action.payload.quantity <= 0) {
                    return { ...state, products: state.products.filter((_, _index) => _index !== index) };
                } else {
                    const temp = [...state.products];
                    temp[index] = { ...action.payload };
                    return { ...state, products: temp }
                }
            }
        }
    }
});


export default cartSlice;