import NimbeyLogo from "../static/img/logos/NimbeyLogo.svg";
import { useState } from "react";
import { waitList } from "../api/init";
// import { useNavigate } from "react-router-dom"; // uncomment when login need to be uncommented

function Nimbey() {
  // const navigate = useNavigate(); // uncomment when login need to be uncommented
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isInList, setIsInList] = useState(undefined);

  const handleToolTip = (evt) => {
    const toolTip = document.querySelector(
      "div[aria-describedby='terms&conditions']"
    );

    const alignTip = () => {
      const positions = toolTip.getBoundingClientRect();
      const pp = document.getElementById("root").getBoundingClientRect();
      if (pp.right < positions.right) {
        toolTip.style.left = `${pp.right - positions.right - 25}px`;
      }
      if (pp.bottom < positions.bottom) {
        toolTip.style.bottom = `${pp.bottom - positions.bottom - 25}px`;
      }
    };
    const deAlign = () => {
      toolTip.style = "";
      toolTip.style.top = "1.5rem";
      toolTip.style.left = "0px";
    };

    if (evt.type === "mouseenter") {
      toolTip.classList.remove("hidden");
      alignTip();
    } else if (evt.type === "mouseleave") {
      toolTip.classList.add("hidden");
      deAlign();
    } else {
      toolTip.classList.toggle("hidden");
      if (!toolTip.classList.contains("hidden")) alignTip();
      else deAlign();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    if (isInList !== undefined) {
      return setIsInList(undefined);
    }
    if (e.target.reportValidity()) {
      setLoading(true);
      waitList({ email })
        .then(([status, _]) => {
          if (status === 200) {
            setEmail("");
            setIsInList(true);
          } else {
            setIsInList(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div
      className={
        "w-full flex-1 flex flex-col bg-[#13151F] overflow-hidden overflow-y-auto"
      }
    >
      <div
        className={
          "w-full flex-1 flex flex-col items-center justify-start relative"
        }
      >
        <div
          className={
            "flex flex-col gap-8 items-center h-full w-full p-6 lg:p-12 max-w-screen-2xl"
          }
        >
          <div className={"flex items-center justify-between w-full"}>
            <img src={NimbeyLogo} alt={"Nimbey"} className={"h-8 lg:h-14"} />
            <div className="flex gap-4">
              {/*
              <button
                onClick={() => navigate("/staging-app/login")}
                className={
                  "p-4 px-8 text-xs lg:text-md font-semibold rounded-md bg-[#C1FF72] text-[#13151F]"
                }
              >
                Login
              </button>
              // if you're uncommenting login, then please also uncomment the above useNavigate() hook
              */}
              <a
                href={"https://cal.com/agrima/nimbey"}
                target={"_blank"}
                rel={"noreferrer"}
                className={
                  "p-4 px-8 text-xs lg:text-md font-semibold rounded-md bg-[#C1FF72]"
                }
              >
                Request Demo
              </a>
            </div>
          </div>
          <div
            className={
              "flex flex-col lg:flex-1 rounded-md lg:rounded-xl border-2 md:border-0 md:border-l-4 border-[#C1FF72] items-start mb-10 lg:mb-0 justify-center w-full bg-[#1E202A] p-4 py-20 md:py-24 md:px-16 lg:px-24"
            }
          >
            <span
              className={
                "text-[#C1FF72] text-lg lg:text-xl md:px-0.5 font-semibold"
              }
            >
              Powered by Gen-Ai
            </span>
            <span
              className={
                "text-white text-5xl lg:text-7xl font-bold leading-tight"
              }
            >
              Next Generation <br /> Supply Chain.
            </span>
            <span
              className={"text-gray-400 text-md lg:text-2xl font-medium mt-4"}
            >
              <span className={"text-white font-semibold mr-1 lg:mr-2"}>
                Built for resilience.
              </span>
              Stay steps ahead of your competitors,{" "}
              <br className={"hidden lg:inline-block"} /> increase
              profitability. Its nimble and easy.
            </span>
            <form
              onSubmit={handleSubmit}
              className={
                "flex flex-col md:flex-row lg:items-center w-full md:w-1/2 gap-4 justify-start mt-10 text-md font-semibold "
              }
            >
              <input
                value={email}
                type={"email"}
                onChange={(e) => setEmail(e.target.value)}
                className={
                  "outline-0 text-center lg:text-left border-0 flex-[2] p-3 px-8 rounded-md bg-gray-600 text-gray-50"
                }
                placeholder={"Email Address"}
                required={true}
              />
              <button
                type={"submit"}
                className={
                  "whitespace-nowrap flex-[1] p-3 px-8 rounded-md bg-[#C1FF72] flex items-center justify-center outline-0"
                }
              >
                {loading ? (
                  <span className="material-symbols-rounded animate-spin rounded-full flex">
                    progress_activity
                  </span>
                ) : isInList === undefined ? (
                  "Join with waitlist"
                ) : isInList === false ? (
                  <span className={"text-sm leading-6"}>Try again later!</span>
                ) : (
                  "You are in!"
                )}
              </button>
            </form>
            <span
              id={"terms&conditions"}
              className={
                "text-gray-400 text-sm lg:text-md font-medium mt-4 lg:mt-8"
              }
            >
              By signing up you agree to our{" "}
              <button
                onMouseEnter={handleToolTip}
                onMouseLeave={handleToolTip}
                onClick={handleToolTip}
                className={"text-white font-medium relative"}
              >
                terms & conditions
                <div
                  className={
                    "hidden text-left bg-[#13151F] rounded-md h-fit absolute w-[360px] z-10"
                  }
                  aria-describedby={"terms&conditions"}
                  onClick={handleToolTip}
                >
                  <div className={"bg-[#7e7f844d] px-4 py-2 rounded-md"}>
                    <span className={"font-normal text-white text-xs"}>
                      By signing up, you agree to our Privacy Policy and Terms
                      of Service and consent to receive product updates and
                      offers via email, which you can opt out of anytime. Demo
                      access with features may vary and data may be collected
                      for improvement purposes.
                    </span>
                  </div>
                </div>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className={"p-4 2xl:hidden"}></div>
    </div>
  );
}

export default Nimbey;
